import React from "react";

import {Link} from "gatsby";
import styles from "./project_link.module.css";

export default (props) => (
  <li className={styles.li}>
    <h3 className={styles.h3}>
      <Link className={styles.link} to={props.link}>{props.children}</Link>
    </h3>
    <p className={styles.p}>{props.subtitle}</p>
  </li>
);
