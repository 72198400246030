import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import ProjectLink from "../../components/project_link";
import ProjectList from "../../components/project_list";

export default ({ data }) => (
    <Layout>
        <h2>Projects</h2>
        This page is a work in progress. I'm slowly adding various projects I've done to this list. Stay tuned!
        <ProjectList>
            {data.allMarkdownRemark.edges.map(({ node }) => (
                <ProjectLink link={"/en/projects/" + node.frontmatter.path} subtitle={node.frontmatter.subtitle}>
                    {node.frontmatter.title}
                </ProjectLink>
            ))}
        </ProjectList>
    </Layout>
);

export const query = graphql`
    {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/projects_en/"}}, sort: {order: ASC, fields: frontmatter___priority}) {
        edges {
          node {
            frontmatter {
              path
              title
              subtitle
              priority
            }
          }
        }
      }
    }
`